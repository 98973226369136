<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">{{ $t('BOT.TITLE_EDIT') }}</h5>
    </div>
  </template>
  <template #toolbar>
    <div class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyBotIndex' }" class="btn btn-secondary font-weight-bold btn-sm">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Navigation/Angle-double-left.svg" />
        </span>
        {{ $t('BASE.BACK') }}
      </router-link>
    </div>
  </template>
  <template #body>
    <entity-form :entity="entity" />
  </template>
</KTCard>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Repo from '@/core/repository/company/botRepository'
import { Plain, ArrayFormat } from '@/helpers/normalizers'
import KTCard from '@/view/content/Card'
import EntityForm from '@/components/company/bot/Form'

export default {
  name: 'CompanyBotEdit',
  components: {
    EntityForm,
    KTCard,
  },
  data() {
    return {
      loading: false,
      entity: null,
      normalizers: {
        uuid: Plain(),
        name: Plain(),
        token: Plain(),
        notifyOrderStatuses: ArrayFormat(),
      },
      statuses: [
        {
          uuid: 'in_progress',
          name: this.$t('BOT.IN_PROGRESS'),
        },
        {
          uuid: 'complete',
          name: this.$t('BOT.COMPLETE'),
        },
        {
          uuid: 'cancel',
          name: this.$t('BOT.CANCEL'),
        },
      ],
    }
  },
  mounted() {
    this.loading = true
    Repo.find(this.$route.params.id)
      .then((entity) => {
        entity.isNew()
        this.entity = {
          ...entity,
          notifyOrderStatuses: entity.notifyOrderStatuses
            .map((notifyOrderStatus) => this.statuses.find((status) => notifyOrderStatus === status.uuid)),
        }
        this.loading = false
      })
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('BOT.TITLE_EDIT') }])
  },
}
</script>

<style scoped>

</style>
