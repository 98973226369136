import Vue from 'vue'

export const ArrayFormat = (path = 'uuid') => (rows) => {
  return rows instanceof Array ? rows.map((r) => r[path]) : []
}

export const Choice = (path = 'uuid') => (choice) => {
  return choice ? choice[path] : null
}

export const DateFormat = (format) => (date) => {
  return Vue.prototype.$moment(date).format(format)
}

export const Plain = () => (val) => {
  return val
}
